exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-konzerte-js": () => import("./../../../src/pages/konzerte.js" /* webpackChunkName: "component---src-pages-konzerte-js" */),
  "component---src-pages-lyrics-js": () => import("./../../../src/pages/lyrics.js" /* webpackChunkName: "component---src-pages-lyrics-js" */),
  "component---src-pages-musik-js": () => import("./../../../src/pages/musik.js" /* webpackChunkName: "component---src-pages-musik-js" */),
  "component---src-pages-projekte-js": () => import("./../../../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-lyrics-js": () => import("./../../../src/templates/lyrics.js" /* webpackChunkName: "component---src-templates-lyrics-js" */)
}

